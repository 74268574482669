import {BrowserDetails} from "../model/BrowserDetails";
import {devLogger} from "../util/loggingUtils";

export const getBrowserDetails = async () : Promise<BrowserDetails> => {
    const colorDepth = window.screen.colorDepth;
    const language = navigator.language;
    const browserDetails: BrowserDetails = {
        browserJavaEnabled: true,
        browserScreenWidth: window.innerWidth,
        browserScreenHeight: window.innerHeight,
        browserColorDepth: colorDepth === 1 ? `${colorDepth}bit`: `${colorDepth}bits`,
        browserLanguage: language,
        browserTimeZone: new Date().getTimezoneOffset()
    }
    devLogger("Browser details: " + JSON.stringify(browserDetails));


    return browserDetails;
}

