// @flow
import React from "react";
import Spinner from "react-bootstrap/Spinner";

const LoadingMessage = () => {
    return (
        <div><Spinner size="sm" animation="border" /> Loading...</div>
    );
};

export default LoadingMessage;
