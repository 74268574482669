// @flow
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

const SpinnerButton = (props) => {

   return (
        <Button {...props} disabled>
            <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            {props.text}
        </Button>
    );
};

export default SpinnerButton;
