import React from "react";
import {useConfig} from "../context/configContext";
import {Container, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";


const VersionDetails = () => {
    const {appVersion, gitHash} = useConfig();
    return (
        <>
            <h2 className="title" style={{fontWeight: 900}}>Version details</h2>
            <Container fluid style={{maxWidth: '736px'}}>
                <Row style={{display: 'flex', alignItems: 'center'}}>
                    <Col id="appVersion">App version: {appVersion}</Col>
                </Row>
                <Row style={{display: 'flex', alignItems: 'center'}}>
                    <Col id="gitHash" >Git hash: {gitHash}</Col>
                </Row>
            </Container>
        </>

    );
}

export default VersionDetails