// @flow
import React, {useState} from "react";
import { browserName, browserVersion, osVersion, isIOS } from "react-device-detect";
import AlertMessage from "./AlertMessage";
import Button from "react-bootstrap/Button";

const alertLevel = "warning";
//Used to set specific browser versions
const unsupportedVersions = new Map();

// e.g. Using a very old IE version as an example
unsupportedVersions.set("MSIE", ["7"]);

const BrowserVersionMessage = ({children}) => {
    const [acknowledged, setAcknowledged] = useState(false);

    const renderCompatabilityMessage = (message) => {
        return (
            <>
                <AlertMessage message = {message} messageLevel={alertLevel}/>
                <Button variant="primary" onClick={() => setAcknowledged(true)} style={{fontSize: '18px'}}>
                    Continue
                </Button>
            </>
        );
    }

    if (isIOS && osVersion.includes("12.4")) {
        const alertMessage = "Your OS maybe out of date and is not supported. " +
            "We highly recommend you update your OS from the device settings. " +
            "Once that is done you can come back and reload this page.";
        if (!acknowledged) {
            return (
                <>
                    {renderCompatabilityMessage(alertMessage)}
                </>
            );
        }
    }
    if (isUnsupportedVersion(browserName, browserVersion)) {
        const alertMessage = "Your browser maybe out of date and is not supported. " +
            "We highly recommend you update your browser from the mobile app store or within your browser settings. " +
            "Once that is done you can come back and reload this page.";
        if (!acknowledged) {
            return (
                <>
                    {renderCompatabilityMessage(alertMessage)}
                </>
            );
        }
    }
    return (<>{children}</>);
};

const isUnsupportedVersion = (name, version) => {
    return !!(unsupportedVersions.has(name) && unsupportedVersions.get(name).find(value => value === version));

};

export default BrowserVersionMessage;
