// @flow
import React from "react";
import {useConfig} from "../context/configContext";
import {Brand} from "../model/Brand";

const HeaderLogo = () => {

    const { brand } = useConfig();
    const renderLogo = () => {

        if (brand === Brand.PAK_N_SAVE) {
          return (
              <img data-testid="pns-image" style={{width: '225px', maxWidth: '100%'}} src={process.env.PUBLIC_URL + "/images/logo/pns.jpg"} alt="PAKnSAVE Online"/>
          )
        }
        else {
            return (
                <svg data-testid="nw-svg" style={ window.innerWidth > 576 ? {height: "50px"} : {height: "50px", width: "60px"}}>
                    <path d="M59.2505415,19.2729857 C58.7992345,18.3787937 58.1203777,17.6001647 57.2879249,17.0204554 L35.2952416,1.70931187 C33.8028945,0.669245322 32.0507614,0.119847693 30.2303635,0.119847693 C28.4099655,0.119847693 26.6597286,0.669245322 25.1654853,1.70931187 L3.17469825,17.0204554 C2.34224542,17.6001647 1.66338867,18.3787937 1.21018542,19.2729857 C0.783529637,20.1217103 0.555979888,21.074631 0.555979888,22.0313407 C0.555979888,22.9880504 0.783529637,23.9428656 1.21018542,24.7896957 C1.66338867,25.6838877 2.34224542,26.4625168 3.17469825,27.0441205 L25.1654853,42.355264 C26.6597286,43.3953306 28.4099655,43.9447282 30.2303635,43.9447282 C32.0507614,43.9447282 33.8028945,43.3953306 35.2952416,42.355264 L57.2879249,27.0441205 C58.1203777,26.4625168 58.7992345,25.6838877 59.2505415,24.7896957 C59.6790935,23.9428656 59.904747,22.9880504 59.904747,22.0313407 C59.904747,21.074631 59.6790935,20.1217103 59.2505415,19.2729857" id="Fill-3" fill="#231F20"/>
                    <path d="M35.026164,37.5434884 C35.3959324,36.975146 35.7884557,36.3196578 36.1923565,35.5713403 C36.8787983,34.2963589 37.4723238,32.9721212 37.9691408,31.6137829 L43.5422134,31.6137829 L35.026164,37.5434884 Z M31.2564232,39.0325456 L31.2564232,31.6137829 L35.7732857,31.6137829 C34.360581,35.1772897 32.4719181,37.8409211 31.2564232,39.0325456 Z M29.204683,39.0325456 C27.989188,37.8428155 26.0986289,35.1791842 24.6878204,31.6137829 L29.204683,31.6137829 L29.204683,39.0325456 Z M16.9188927,31.6137829 L22.4900691,31.6137829 C22.9868861,32.9721212 23.5823079,34.296359 24.2668534,35.5713404 C24.6707542,36.3177634 25.0632775,36.9732517 25.4330459,37.5415941 L16.9188927,31.6137829 Z M25.4292534,6.52525587 C25.0613813,7.09170378 24.668858,7.74529751 24.2668534,8.48982603 C23.5804117,9.76480743 22.9868861,11.0909397 22.4900691,12.4492779 L16.9188927,12.4492779 L25.4292534,6.52525587 Z M29.204683,5.02862093 L29.204683,12.4492779 L24.6859242,12.4492779 C26.0986289,8.88198227 27.989188,6.22024545 29.204683,5.02862093 Z M31.2564232,5.03051547 C32.4738144,6.22214002 34.3624773,8.8838769 35.7732857,12.4492782 L31.2564232,12.4492782 L31.2564232,5.03051547 Z M43.5422134,12.4492784 L37.9691408,12.4492784 C37.4742201,11.0909401 36.8787983,9.76480778 36.1923565,8.48982631 C35.790352,7.74529775 35.3978286,7.08980951 35.0280603,6.52336157 L43.5422134,12.4492784 Z M56.1143371,18.7029387 L34.1216538,3.39179492 C31.7835801,1.76444123 28.677526,1.76444123 26.3394524,3.39179492 L4.34866533,18.7029387 C2.02955414,20.3170311 2.02955414,23.7460301 4.34866533,25.3620169 L26.3394524,40.6731607 C28.677526,42.3005144 31.7835801,42.3005144 34.1216538,40.6731607 L56.1143371,25.3620169 C58.4334483,23.7460301 58.4334483,20.3170311 56.1143371,18.7029387 Z" id="Fill-5" fill="#FFFFFF"/>
                    <path d="M42.3121174,27.046205 L38.599264,27.046205 L36.9267733,21.3059468 L35.2542827,27.046205 L31.753809,27.046205 L28.9947683,17.0187506 L32.4364583,17.0187506 L33.8851917,22.9427729 L35.4761437,17.0187506 L38.6864914,17.0187506 L40.3476046,23.2098938 L41.9309716,17.0187506 L45.0901206,17.0206451 L42.3121174,27.046205 Z M27.8702933,27.0443105 L24.5651332,27.0443105 L19.6557473,20.7944386 L19.6557473,27.0443105 L16.6634681,27.0443105 L16.6634681,17.0168561 L20.5735313,17.0187506 L24.8931841,22.6851243 L24.8950803,17.0187506 L27.8702933,17.0187506 L27.8702933,27.0443105 Z M54.9392322,20.3852319 L46.4876553,14.5009938 L13.9745886,14.5009938 L5.52111542,20.3852319 C4.97499602,20.7660213 4.66211511,21.3665698 4.66211511,22.0315303 C4.66211511,22.6983854 4.97499602,23.2989338 5.52111542,23.6778288 L13.9745886,29.5639613 L46.4876553,29.5639613 L54.9392322,23.6778288 C55.4872479,23.2989338 55.8001288,22.6983854 55.8001288,22.0315303 C55.8001288,21.3665698 55.4872479,20.7660213 54.9392322,20.3852319 Z" id="Fill-7" fill="#E11A2C"/>
                    <polygon id="Fill-9" fill="#E11A2C" points="66.2808806 12.4500363 72.4778188 12.4500363 78.6197658 23.2504359 78.6197658 12.4500363 83.4210655 12.4500363 83.4210655 31.6107522 78.1096751 31.6107522 71.1087278 19.5789441 71.1087278 31.6107522 66.2808806 31.6107522"/>
                    <polygon id="Fill-11" fill="#E11A2C" points="86.1165819 14.9031913 97.6704204 14.9031913 97.6704204 18.5955222 90.8875416 18.5955222 90.8875416 21.46944 96.2918482 21.46944 96.2918482 24.8813886 90.8875416 24.8813886 90.8875416 27.9182313 98.0458775 27.9182313 98.0458775 31.6105622 86.1165819 31.6105622"/>
                    <polygon id="Fill-13" fill="#E11A2C" points="99.4957487 14.9031913 104.33687 14.9031913 106.489111 25.3246957 108.734268 14.9031913 112.991345 14.9031913 115.424231 25.4194194 117.692144 14.9031913 122.136949 14.9031913 118.230678 31.6105622 113.224584 31.6105622 110.653271 21.0015048 108.057308 31.6105622 103.379264 31.6105622"/>
                    <polygon id="Fill-15" fill="#E11A2C" points="128.512703 12.4500363 134.064917 12.4500363 136.533832 24.4022764 139.108936 12.4500363 143.989879 12.4500363 146.779259 24.5102615 149.380911 12.4500363 154.478026 12.4500363 149.999088 31.6107522 144.259146 31.6107522 141.306688 19.4444364 138.329579 31.6107522 132.965093 31.6107522"/>
                    <path d="M166.838528,23.3127638 L166.838528,23.1252108 C166.838528,20.1565691 165.340492,18.40418 162.977767,18.40418 C160.615042,18.40418 159.141658,20.1111017 159.141658,23.149839 L159.141658,23.3354975 C159.141658,26.3742348 160.685204,28.1285183 163.002419,28.1285183 C165.365143,28.1285183 166.838528,26.3041393 166.838528,23.3127638 M154.133667,23.3601256 L154.133667,23.1725726 C154.133667,18.076436 157.971673,14.6644872 162.977767,14.6644872 C168.078674,14.6644872 171.844623,17.8661493 171.844623,23.0551152 L171.844623,23.2426682 C171.844623,28.3842722 168.311913,31.8909447 162.907606,31.8909447 C157.5033,31.8909447 154.133667,28.3842722 154.133667,23.3601256" id="Fill-17" fill="#E11A2C"/>
                    <path d="M180.115108,22.5189786 C181.728815,22.5189786 182.525239,21.8407567 182.525239,20.4862074 L182.525239,20.3914837 C182.525239,19.0369343 181.683305,18.4989035 180.137863,18.4989035 L178.571562,18.4989035 L178.571562,22.5189786 L180.115108,22.5189786 Z M173.798706,14.8994017 L180.348346,14.8994017 C184.724887,14.8994017 187.157773,16.6745245 187.157773,20.2058254 L187.157773,20.2986546 C187.157773,22.6819038 185.894871,24.015614 184.138946,24.6919415 L188.140029,31.6105631 L183.086528,31.6105631 L179.694141,25.627812 L178.571562,25.627812 L178.571562,31.6105631 L173.798706,31.6105631 L173.798706,14.8994017 Z" id="Fill-19" fill="#E11A2C"/>
                    <polygon id="Fill-21" fill="#E11A2C" points="190.252828 14.8984546 195.073091 14.8984546 195.073091 27.8945509 201.389492 27.8945509 201.389492 31.6115103 190.252828 31.6115103"/>
                    <path d="M209.67913,27.9174741 C212.532983,27.9174741 213.79778,26.4208391 213.79778,23.3120063 L213.79778,23.1263478 C213.79778,19.9455249 212.532983,18.5909756 209.631724,18.5909756 L208.579306,18.5909756 L208.579306,27.9174741 L209.67913,27.9174741 Z M203.512532,14.8986446 L209.595695,14.8986446 C215.538536,14.8986446 218.555466,17.8672864 218.555466,23.0562526 L218.555466,23.2438056 C218.555466,28.4555055 215.468375,31.6117003 209.57294,31.6117003 L203.512532,31.6117003 L203.512532,14.8986446 Z" id="Fill-23" fill="#E11A2C"/>
                </svg>
            )
        }
    }

    return (
        renderLogo()
    );
};

export default HeaderLogo;
