// @flow
import React from "react";
import {Card} from "react-bootstrap";
import {useConfig} from "../context/configContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Brand} from "../model/Brand";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const Footer = () => {

    const { brand, appReturnUrl, appVersion, gitHash } = useConfig();

    const getDisclaimer = () => {
        return `© ${new Date().getFullYear()} ${brand === Brand.PAK_N_SAVE ? "PAK'nSAVE":"New World"}. All rights reserved.`;
    }

    const getLink = () => {
        if (brand === Brand.PAK_N_SAVE) {
            if (appReturnUrl) {
                return (
                    <>
                        Back to <a className="footerLink" href={appReturnUrl}>
                            <u>PAK'nSAVE app</u>
                        </a>
                    </>
                )
            }
            return (
                <>
                    Back to <a className="footerLink" href="https://www.paknsaveonline.co.nz">
                        <u>PAK'nSAVE home page</u>
                    </a>
                </>
            )
        }
        else {
            if (appReturnUrl) {
                return (
                    <>
                        Back to <a className="footerLink" href={appReturnUrl}>
                            <u>New World app</u>
                        </a>
                    </>
                )
            }
            return (
                <>
                    Back to <a className="footerLink" href="https://www.newworld.co.nz/shop">
                        <u>New World home page</u>
                    </a>
                </>
            )
        }
    }

    return (
        <Card.Footer className="cardFooter">
            <Row>
                <OverlayTrigger
                    placement="top"
                    transition={false}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="button-tooltip">
                            <p>App version: {appVersion}</p>
                            <p>Commit hash: {gitHash}</p>
                        </Tooltip>}>
                    {({ ref, ...triggerHandler }) => (
                        <Col ref={ref} {...triggerHandler} className="left" xs="12" lg="6">{getDisclaimer()}</Col>
                    )}
                </OverlayTrigger>
                <Col className="right">{getLink()}</Col>
            </Row>
        </Card.Footer>
    );
};

export default Footer;
