import {isValid} from "luhn-js";
import {CardType} from '../model/CardType';
import {CardValidation} from '../model/CardValidation';

export const getCardDisplay = (maskedNumber) => {
 return `ending in ${maskedNumber ? maskedNumber.substring(maskedNumber.length - 4) : ''}`;
}

export const getExpiryDisplay = (expiryDate) => {
 const expirySplit = expiryDate.split('-');
 return `${expirySplit[1]}/${expirySplit[0].substring(2)}`;
}

export const handleCvv = (oldCvv, inputCvv) => {
   const cardPattern = /^[0-9\b]+$/;

   if (inputCvv.length > 0) {
       if (cardPattern.test(inputCvv)) {
           return inputCvv;
       } else {
           return oldCvv;
       }
   } else {
       return inputCvv;
   }
}

// refactored this to reduce cognitive load but still is not making heaps of sense.
export const handleExpiry = (oldMonth, oldYear, oldExpiry, inputExpiry, inputKey) => {
     const expiryPattern = /^[0-9\b/]+$/;
     let handledExpiry = {
        expiry: oldExpiry,
        expiryMonth: oldMonth,
        expiryYear: oldYear
    }
     if (inputExpiry.length > 0) {
         if (!expiryPattern.test(inputExpiry) || (inputExpiry.indexOf("/") > -1 && inputExpiry.length < 3)) {
            return handledExpiry;
         }
     }
     if (inputExpiry && inputExpiry.length === 2) {
         handledExpiry.expiry = inputKey === 8 ? inputExpiry.substring(0, 1) : inputExpiry + "/"
         return handledExpiry;

     } else if (inputExpiry && inputExpiry.length > 2 ) {
         let displayExpiry = inputExpiry.replace(/\//g, "");
         displayExpiry = displayExpiry.substring(0, 2) + "/" + displayExpiry.substring(2);
         handledExpiry.expiry = displayExpiry;
         if(displayExpiry.length === 5) {
             handledExpiry.expiryMonth = displayExpiry.slice(0, 2);
             handledExpiry.expiryYear = displayExpiry.slice(3);
         }
         return handledExpiry;
     } else {
         handledExpiry.expiry = inputExpiry;
         return handledExpiry;
     }
}

export const handleCard = (oldCard, oldCardDisplay, inputCard, inputKey) => {
     const cardPattern = /^[0-9\b ]+$/;

     if (inputCard && inputCard.length > 0) {
         if (!cardPattern.test(inputCard)) {
             return {
               cardNumber: oldCard,
               cardNumberDisplay: oldCardDisplay,
             };
         }
         let cardNumber = inputCard.replace(/ /g, "");

         if (inputKey === 8 && cardNumber === oldCard) { // if it is deleting space
             cardNumber = cardNumber.slice(0, -1);
         }

         const cardType = getCardType(cardNumber);
         const newInfo = {
            cardNumber: cardNumber,
            cardNumberDisplay: getDisplayCardValue(cardNumber),
            cardType: cardType
        }
        return checkForDinersClubCVVAndAddEmptyCVV(newInfo);
     } else {
          return {
             cardNumber: oldCard,
             cardNumberDisplay: inputCard,
           };
     }
}

export const getDisplayCardValue = (cardNumber: string) => {
    let displayCard = "";
    for (let i = 0; i < cardNumber.length; i++) {
        displayCard += cardNumber.charAt(i);
        if (i % 4 === 3 && i > 0) {
            displayCard += " ";
        }
    }
    return displayCard.trimEnd();
}
// I have moved this out to it's own function but am unsure why this is necessary to add an empty CVV to Diners cards
export const checkForDinersClubCVVAndAddEmptyCVV = (newInfo: any) => {
    if (newInfo.cardType === CardType.DINERS_CLUB) {
        return {
            ...newInfo,
            cvv: ''
        }
    } else {
        return newInfo;
    }
}

export const isValidExpiry = (month, year) => {
    const parsedMonth = parseInt(month.toString());
    const parsedYear = parseInt(year.toString());
    if (!(parsedMonth && parsedYear)) {
        return false;
    }

    if (parsedMonth < 1 || parsedMonth > 12) {
        return false;
    }

    const cur = new Date();
    const expiryDate = new Date(2000 + parsedYear, parsedMonth - 1);
    const currentDate = new Date(cur.getFullYear(), cur.getMonth());

    return expiryDate >= currentDate;

}

export const isValidCardNumber = (cardNumber: string) : boolean => {
    try {
        return isValid(cardNumber);
    } catch (error) {
        return false;
    }
}

export const isValidCVV = (cardNumber: string, cvv: string): boolean => {
    const cardType = getCardType(cardNumber);
    return cardType === CardType.DINERS_CLUB || (cvv != null && cvv.toString().length > 0);
}

export const isValidName = (cardName: string): boolean => {
    return cardName != null && cardName.toString().length > 0
}

export const getCardType = (cardNumber) => {
    const visaPattern = /^4\d{6,}$/;
    const masterPattern = /^5[1-5]\d{5,}$|^222[1-9]\d{3,}$|^22[3-9]\d{4,}$|^2[3-6]\d{5,}$|^27[01]\d{4,}$|^2720\d{3,}$/;
    const dinersCardPattern = /^3(?:0[0-5]|[68]\d)\d{4,}$/;
    const aeCardPattern = /^3[47]\d{5,}$/;
    if (dinersCardPattern.test(cardNumber)) {
        return CardType.DINERS_CLUB;
    } else if (visaPattern.test(cardNumber)) {
        return CardType.VISA;
    } else if (masterPattern.test(cardNumber)) {
        return CardType.MASTERCARD;
    } else if (aeCardPattern.test(cardNumber)) {
        return CardType.AMERICAN_EXPRESS;
    }else {
        return null;
    }
}

/*
** For test card number: http://docs.dev.paymark.nz/click/#test-card-details
*/
export const validCardDetails = (expiryMonth, expiryYear, cardNum, cvv, name) : CardValidation => {
    const validExpiry = isValidExpiry(expiryMonth, expiryYear);
    const validCard =  isValidCardNumber(cardNum);
    const validCvv = isValidCVV(cardNum, cvv);
    const validName = isValidName(name);

    return {
        failed: !(validCard && validExpiry && validCvv && validName),
        invalidExpiry: !validExpiry,
        invalidCreditCard: !validCard,
        invalidCvv: !validCvv,
        invalidName: !validName
        } as CardValidation
}