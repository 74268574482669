import {deleteCard, getCards} from '../client/paymentEdge'
import React, {useEffect, useState} from "react";
import {useConfig} from "../context/configContext";
import LoadingMessage from "../component/LoadingMessage";
import AlertMessage from "../component/AlertMessage";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardImage from "../component/CardImage";
import {getCardDisplay, getExpiryDisplay} from "../util/cardUtils";
import Button from "react-bootstrap/Button";
import {Card} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DeleteCardModal from "../component/DeleteCardModal";
import {CreditCard} from "../model/CreditCard";
import delay from "delay";

const ListCreditCards = () => {
    const {apiToken, configInitialised, handleRedirect, brand} = useConfig();
    const [cards, setCards] = useState<CreditCard[]>([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState("");
    const [messageLevel, setMessageLevel] = useState("");
    const [tokenExpired, setTokenExpired] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCards = async () => {
            try {
                const result = await getCards(apiToken);
                setCards(result.cards);
                setMessageLevel("");
                setMessage("");
                setTokenExpired(false);
            } catch (error: any) {
                setMessage(error?.message);
                setMessageLevel(error?.messageLevel);
                setTokenExpired(error.tokenExpired || false)
            } finally {
                setDeleteModal(false);
                setDeleteId("");
                setLoaded(true);
            }
        }
        if (!loaded && configInitialised && (apiToken !== '' || !tokenExpired)) {
            fetchCards();
        }
    }, [loaded, apiToken, configInitialised, tokenExpired]);

    const removeClicked = (cardId) => {
        setDeleteModal(true);
        setDeleteId(cardId);
    }

    const handleDeleteCard = async () => {
        try {
            setProcessing(true);
            await deleteCard(deleteId, apiToken);
            setCards([]);
            setLoaded(false);
            delay(2000);
            const result = await getCards(apiToken);
            setCards(result.cards);
        } catch (error: any) {
            setMessageLevel(error.messageLevel);
            setMessage(error.message);
            setTokenExpired(error.tokenExpired || false)
        } finally {
            setDeleteModal(false);
            setDeleteId("");
            setProcessing(false);
        }
    }

    return (
        <>
            <h2 className="title" style={{fontWeight: 900}}>Payment methods</h2>
            <h6 style={{fontWeight: 300, marginBottom: '40px'}}>Credit cards details</h6>
            {!loaded &&
                <LoadingMessage />
            }
            {message && messageLevel &&
                <AlertMessage message={message} messageLevel={messageLevel}/>
            }
            {cards && cards.length > 0 && !tokenExpired &&
                <div className="">
                    {
                        cards.map(
                            card => (
                                <Container fluid key={card.id} style={{maxWidth: '736px'}}>
                                    <Row >
                                        <Col id="typeImage" lg={1} xs={3} style={{display: 'flex', alignItems: 'center'}}><CardImage cardType={card.cardType} /></Col>
                                        <Col id="cardName" lg={6} xs={6}><div style={{fontSize: "22px", paddingLeft: '16px'}} className="left">{card.cardName}</div>
                                            <div className="cardNum left" style={{paddingLeft: '16px', paddingBottom: '5px'}}>{getCardDisplay(card.maskedCreditCard)}</div></Col>
                                        <Col lg={3} xs={3} className="listValid" style={{display: 'grid', paddingTop: '5px'}}>
                                            <div className="">Valid until</div>
                                            <div className="cardNum ">{getExpiryDisplay(card.expiryDate)}</div></Col>
                                        <Col lg={2} style={{display: "flex", alignItems: "center", paddingLeft: '16px', paddingRight: '16px'}}>
                                            <Button className="removeButton" variant="outline-primary" onClick={() => {removeClicked(card.id)}}>Remove</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            )
                        )
                    }
                </div>
            }
            {((!cards || cards?.length === 0) && loaded && !tokenExpired) &&
                <Card.Text>No credit cards found</Card.Text>
            }
            <div className="mt-4 mb-4">
            {!tokenExpired && loaded
                ? <Button variant="primary"
                        id="btnRegister"
                        onClick={() =>  {
                            navigate("/register");
                        }
                        }>
                    Add payment method
                </Button>

                : <Button variant="primary" onClick={() => {handleRedirect(false)}}>
                    OK
                </Button>
            }
            </div>
            <DeleteCardModal
                showModal = {deleteModal}
                handleClose = {() => setDeleteModal(false)}
                brand={brand}
                handleDelete={handleDeleteCard}
                processingCard={processing}
            />
        </>
    );
}

export default ListCreditCards