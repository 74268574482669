import {ListOfCreditCards} from "../model/ListOfCreditCards";
import axios, {AxiosError} from "axios";
import {getConfiguration} from "../config/config";
import {Message} from "../model/Message";
import {CardStatus} from "../model/CardStatus";
import {AuthResult} from "../model/AuthResult";
import {FeatureFlags} from "../model/FeatureFlags";
import {devLogger} from "../util/loggingUtils";
import {handlePaymarkAuthError} from "../util/errorUtils";
import delay from "delay";
import {getBrowserDetails} from "./browserDetails";
import {ErrorWithLevel} from "../model/ErrorWithLevel";

const client = axios.create({
    baseURL: "https://" + getConfiguration().paymentEdgeHost,
    validateStatus: (status: number) => status >= 200 && status <= 302
});

const directPostProxy = getConfiguration().directPostProxy;

const expiredTokenMessage = "Your session has expired";

export const getFeatureFlags = async (apiToken: string): Promise<FeatureFlags> => {
    devLogger("get feature flags");
    try {
        const resp = await client.get(`/v1/edge/feature-flags`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiToken}`
                }
            });
        if (resp.status === 200 && resp?.data) {
            const result = resp.data as FeatureFlags
            result.resolvedFromServer = true;
            return Promise.resolve(result);
        } else {
            return Promise.reject(new ErrorWithLevel(
                "Failed to resolve feature flags"));
        }
    } catch (error) {
        return Promise.reject(new ErrorWithLevel(
            "Something went wrong accessing the microsite's feature flags"));
    }
}

export const loginUserUsingSecureLookup = async (lookupKey: string): Promise<string> => {
    devLogger("get token via lookup key");
    try {
        const resp = await client.post(`/v1/edge/secure-login`, {key: lookupKey});
        if (resp.status === 200 && resp?.data?.access_token) {
            return Promise.resolve(resp?.data?.access_token);
        } else {
            return Promise.reject(new ErrorWithLevel(
                "Failed to resolve token using lookup key"));
        }
    } catch (error) {
        return Promise.reject(new ErrorWithLevel(
            "Something went wrong accessing lookup service"))
    }
}

export const getCards = async (apiToken: string): Promise<ListOfCreditCards> => {
    devLogger("get cards");
    try {
        const resp = await client.get(`/v1/edge/payment/card`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiToken}`
                }
            });
        devLogger("Get cards response: " + JSON.stringify(resp));
        if (resp.data === "Token expired or invalid") {
            return Promise.reject(new ErrorWithLevel(expiredTokenMessage, "warning", true));
        } else {
            let data = resp.data as ListOfCreditCards;
            return Promise.resolve({
                cards: data.cards
            });
        }
    } catch (error) {
        devLogger(`Fail to get cards here ${JSON.stringify(error)}`);
        if (error instanceof AxiosError && error.response?.status === 401) {
            return Promise.reject(new ErrorWithLevel(expiredTokenMessage,"warning", true));
        } else {
            return Promise.reject(new ErrorWithLevel(
                "Whoops! Looks like there was an error with adding a new card. As a first step, " +
                "please add the card on a different device or try updating to a newer device version.")
            );
        }
    }
}

export const deleteCard = async (id: string, apiToken: string): Promise<Message> => {
    try {
        devLogger("delete card");
        const resp = await client.delete(`/v1/edge/payment/card/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiToken}`
            }
        });
        devLogger(`delete card response ${JSON.stringify(resp)}`);
        return Promise.resolve({
            message: "Successfully delete card",
            messageLevel: "success"
        } as Message);
    } catch (error) {
        devLogger(`Fail to delete cards`);
        if (error instanceof AxiosError && error.response?.status === 401) {
            return Promise.reject(new ErrorWithLevel(expiredTokenMessage,"warning", true));
        } else {
            return Promise.reject(new ErrorWithLevel(error instanceof AxiosError ?
                error.response?.data?.message :
                "Whoops! Looks like there was an error deleting the card."));
        }
    }

}

const getCardStatus = async (notificationId: string, apiToken: string): Promise<CardStatus> => {
    try {
        const path = `/v1/edge/payment/card/status/${notificationId}`
        devLogger("get card status using " + path);
        const resp = await client.get(path, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiToken}`
            }
        });

        devLogger(`card status ${resp.data?.status}`);
        return Promise.resolve(resp.data);
    } catch (error) {
        devLogger(`Fail to get card status ${error}`, "Fail to get card status");
        return Promise.reject(new ErrorWithLevel(error));
    }
}

export const getAddCardStatus = async (notificationId: string, apiToken: string): Promise<Message> => {
    try {
        const resp = await getCardStatus(notificationId, apiToken);
        devLogger(`Add card response ${JSON.stringify(resp)}`);
        if (resp.status === "ACTIVE") {
            return Promise.resolve({
                message: "Payment method successfully added. Redirecting",
                messageLevel: "success",
            } as Message);
        } else if (resp.status === "ERROR") {
            return Promise.reject(new ErrorWithLevel(resp.errorMessage));
        } else {
            return Promise.resolve(new ErrorWithLevel("Card status pending. Redirecting"));
        }
    } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 401) {
            return Promise.reject(
                new ErrorWithLevel(expiredTokenMessage, "warning", true));
        }
        return Promise.reject(new ErrorWithLevel("Failed to check card status."));
    }
}

export const getPaymarkAuth3ds = async (apiToken: string): Promise<any> => {
    try {

        const browserDetails = await getBrowserDetails();
        devLogger("get 3DS2 auth" + JSON.stringify(browserDetails));
        const resp = await client.post(
            "/v1/edge/payment/auth/3ds",
            browserDetails, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiToken}`
                }

            });
        const result = resp.data as AuthResult;
        devLogger("Post URL: " + JSON.stringify(result));
        return Promise.resolve(result);
    } catch (error) {
        return handlePaymarkAuthError(error);
    }
}

export const getPaymarkAuth = async (apiToken: string): Promise<any> => {
    try {
        devLogger("get auth");
        const resp = await client.get(`/v1/edge/payment/auth`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiToken}`
            }
        });
        const result = resp.data as AuthResult;
        devLogger(JSON.stringify(result));
        return Promise.resolve(result);
    } catch (error) {
        return handlePaymarkAuthError(error);
    }
}

export const addCard3DS2Flow = async (data, postUrl): Promise<any> => {
    try {
        const url = buildProxyUrl(postUrl);
        const resp = await client.post(url, data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "text/html"
            }
        });
        const securityForm = resp.data;
        return Promise.resolve(securityForm);
    } catch (error) {
        devLogger(JSON.stringify("3DS2 post to Epay error: " + JSON.stringify(error)));
        return Promise.reject(new ErrorWithLevel(error));
    }
}

export const addCard3DS2NoChallengeFlow = async (
    data, postUrl, notifictionId, apiToken): Promise<Message> => {
    try {
        await fetch(postUrl, {
            method: 'POST',
            body: data,
            mode: "no-cors",
            redirect: "follow"
        });
    } catch (error) {
        devLogger(JSON.stringify("3DS2 post to Epay error: " + JSON.stringify(error)));
    } finally {
        devLogger("wait for card to make it into our system via provided notification URL")
        await delay(getConfiguration().cardStatusDelay);
    }
    return getAddCardStatus(notifictionId, apiToken)
}

const buildProxyUrl = (postUrl: string) => {
    const query = postUrl.substring(postUrl.lastIndexOf("?"), postUrl.length);
    const directPostProxyUrl = directPostProxy + query;
    devLogger("Post via proxy: " + directPostProxyUrl);
    return directPostProxyUrl;
}

export const completeAddCard3DS2Flow = async (notificationId: string | undefined,
                                              apiToken: string,
                                              resultId: string,
                                              transactionNo: string): Promise<CardStatus> => {
    try {
        const url = "/v1/edge/payment/validate/3ds";
        const resp = await client.post(url,
            {resultId, transactionNo, notificationId},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiToken}`
                }
            });
        return Promise.resolve(resp.data);
    } catch (error) {
        devLogger(JSON.stringify("3DS2 post to Epay error: " + JSON.stringify(error)));
        if (error instanceof AxiosError) {
            if (error.response?.status === 401) {
                return Promise.reject(new ErrorWithLevel(expiredTokenMessage, "warning", true));
            }
        }
        return Promise.reject(new ErrorWithLevel("Failed to check card status."));
    }
}

export const addCard = async (data, postUrl, notificationId, apiToken): Promise<Message> => {
    try {
        devLogger("post to EPAY URL");
        await fetch(postUrl, {
            method: 'POST',
            body: data,
            mode: "no-cors",
            redirect: "follow"
        });
    } catch (error) {
        devLogger(JSON.stringify("Post to Epay error: " + JSON.stringify(error)));
    } finally {
        devLogger("wait for card to make it into our system via provided notification URL")
        await delay(getConfiguration().cardStatusDelay);
    }
    devLogger("Get the resulting card status")
    return getAddCardStatus(notificationId, apiToken);
}