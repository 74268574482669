import React, {useEffect, useState} from 'react';
import Button from "react-bootstrap/Button";
import AlertMessage from "../component/AlertMessage";
import { useConfig } from '../context/configContext';
import { useNavigate } from "react-router-dom";

import LoadingMessage from "../component/LoadingMessage";
import {getPaymarkAuth3ds, addCard3DS2Flow} from "../client/paymentEdge";
import RegisterCardForm from "../component/RegisterCardForm";
import delay from "delay";
import {getConfiguration} from "../config/config";
import {devLogger} from "../util/loggingUtils";


const RegisterCreditCard3dsFlow = () => {
    const {apiToken, handleRedirect} = useConfig();
    const [postUrl, setPostUrl] = useState("");
    const [tokenExpired, setTokenExpired] = useState(false);
    const [notificationId, setNotificationId] = useState("");
    const [processingCard, setProcessingCard] = useState(false)
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [messageLevel, setMessageLevel] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        if (apiToken.length > 0 && !postUrl && !loading && !messageLevel && !processingCard) {
            setLoading(true);
            // get auth if no payment url is available
            const fetchAuth = async (apiToken) => {
                try {
                    setProcessingCard(true);
                    const authResult = await getPaymarkAuth3ds(apiToken);
                    setPostUrl(authResult?.postUrl);
                    setNotificationId(authResult?.notificationId)
                    setLoading(false);
                } catch (e : any) {
                    setTokenExpired(e.tokenExpired || false);
                    setLoading(false);
                    setMessage(e.message);
                    setMessageLevel(e.messageLevel);
                } finally {
                    setProcessingCard(false);
                }
            };
            if (!postUrl || !notificationId) {
                fetchAuth(apiToken);
            }
        } else if (apiToken.length === 0) {
            setTokenExpired(true);
            setLoading(false);
            setMessage("Your session has expired");
            setMessageLevel("warning");
        }
    }, [postUrl, notificationId, apiToken, processingCard, loading, messageLevel]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessingCard(true);
        const data = new FormData(event.target);
        try {
            const result = await addCard3DS2Flow(data, postUrl);
            navigate("/secure-verification",
                {
                    state: {
                        thirdPartyForm: result,
                        notificationId
                    }
                });
        } catch (error: any) {
            devLogger(JSON.stringify(error));
            setMessage("Failed to redirect for verification, card might not support 3DS");
            setMessageLevel( "warning");
            setLoading(false);
            await delay(getConfiguration().cardStatusDelay);
            navigate("/")
        }
    }

    return (
        <div data-testid="3ds-enabled">
            <h2 className="title" style={{fontWeight: 900}}>Add a card</h2>
            <h6 style={{fontWeight: 300}}>Credit cards details</h6>

            {
                loading && <LoadingMessage />
            }
            {
                message &&
                <>
                    <AlertMessage message={message} messageLevel={messageLevel}/>
                    <div className="mt-4 mb-4 center">
                        {tokenExpired &&
                            <Button variant="primary"
                                    onClick={() => handleRedirect(false)}>
                                OK
                            </Button>
                        }
                    </div>
                </>
            }
            {
                postUrl && message.length === 0  &&
                <div>
                    <div>
                        <RegisterCardForm handleSubmit={handleSubmit}
                                          postUrl={postUrl}
                                          tokenExpired={tokenExpired}
                                          processingCard={processingCard}/>
                    </div>
                    <div className="float-right">
                        <img style={{width: '225px', maxWidth: '100%'}} src={process.env.PUBLIC_URL + "/images/3DS_logos.jpg"} alt="3DS Enabled"/>
                    </div>
                </div>
            }
        </div>
    );
}

export default RegisterCreditCard3dsFlow;
