import {AxiosError} from "axios";
import {devLogger} from "./loggingUtils";
import {ErrorWithLevel} from "../model/ErrorWithLevel";

const defaultErrorMessage: string =
    "Sorry this card failed. Please talk to your card provider or try a different type of card."

export const cardStatusErrors: {[id: string]: string} = {
    "200": "Sorry, it looks like there may be insufficient funds on this card. Please check and try again.",
    "201": "Sorry, it looks like this card has expired. Please try again with another card.",
    "202": "Sorry, this transaction was declined. Please call your card provider to resolve any issues.",
    "203": "Sorry, this transaction was declined. Please call your card provider to resolve any issues.",
    "208": "Sorry, this transaction was declined. Please call your card provider to resolve any issues.",
    "270": "Sorry, this transaction was declined. Please try again and if unsuccessful, call your card provider to resolve any issues.",
    "415": "Sorry, this transaction was declined. Please try again and if unsuccessful, call your card provider to resolve any issues.",
    "204": "Sorry, this card doesn't support online transactions. Please try again with another card.",
    "210": "Sorry, this transaction was declined. Please call your card provider to resolve any issues.",
    "301": "Sorry, we are having issues communicating with your bank or card provider. Please try again.",
}

export const mapErrorCodeToMessage = (status: string): string => {
    return cardStatusErrors[status] || defaultErrorMessage;
}
const defaultMessageLevel = "warning";

export const handlePaymarkAuthError = (error) : Promise<ErrorWithLevel> => {
    if (error instanceof AxiosError) {
        devLogger(
            error.response ? JSON.stringify(error.response): JSON.stringify(error),
            "Fail to get direct payment authorisation URL"
        );
        if (error.response?.status === 401) {
            return Promise.reject(new ErrorWithLevel("Your session has expired", defaultMessageLevel, true));
        } else if (error.response?.status === 429) {
            return Promise.reject(
                new ErrorWithLevel(error.response?.data?.message));
        } else if (error.response?.status === 404) {
            return Promise.reject(
                new ErrorWithLevel("Please select a store before adding payment method"));
        }
    }
    return Promise.reject(
        new ErrorWithLevel("Whoops! Looks like there was an error with adding a new card. " +
            "As a first step, please add the card on a different device or try updating to a newer device version."));
}